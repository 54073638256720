
<div class="topbar-blocks-container">
 <div id="playbar"></div>

  <div class="block chat-block-top"
    *ngIf=" currentUser != undefined && currentUser != null &&currentUser.role != 'Administrator' && currentUser.role != 'Admin Management' && currentUser.role != 'Company Administrator' && currentUser.logicRole
    !='academyVp1Lazy' && currentUser.role !=
    'Supplier Employees' && currentUser.role != 'Profile Accademy' && currentUser.role != 'Accademy' && currentUser.role
    != 'Management Accademy' && !isProd && !isEsprinet || currentUser.logicRole == 'studentAcademyLazy'"
  (click)="!chatBlock.blocked ? toChat() : openBlockModal('chat')">
  <div class="pulse-notification" *ngIf="newMessagesLed && newMessagesLed >= 1"></div>
      <img class="icon" *ngIf="!chatBlock.blocked || newMessagesLed >= 1"
        style="cursor: pointer;"
        src="assets/images/new-card-icons/nav-bar-chat-blu-default.svg" alt="">
      <img class="icon" *ngIf="chatBlock.blocked && newMessagesLed == 0"
        src="assets/images/new-card-icons/nav-bar-chat-blu-blocked.svg" alt="">
  </div>

  <div class="block notification-block-top" (click)="goToNotification()">
    <div class="notification-custom custom-bell">
     <div class="pulse-notification" *ngIf="allNotifications && allNotifications >= 1"></div>
     <img class="icon" src="assets/images/new-card-icons/nav-bar-bell-blu-default.svg" alt="">
    </div>
  </div>

  <div class="block credits-block-top"
    *ngIf=" currentUser != undefined && currentUser != null && currentUser.role == 'Client' && numberToken && globalState.isOldPlan == true ">
    <div *ngIf="currentUser.role == 'Client' && numberToken && versionProduct == 2"
      class="notification-custom token-custom">
      <span matTooltip="{{ 'Dashboard.RemainingCredit' | translate }}" matTooltipPosition="above"
        class="token-custom-content">{{numberToken}}</span>
    </div>
    <div
      *ngIf=" currentUser != undefined && currentUser != null && currentUser.role == 'Client' && numberToken && versionProduct == 1 && currentUser.domain_url != null && currentUser.n_max_supplier != null"
      class="notification-custom token-custom">
      <span matTooltip="{{ 'Dashboard.RemainingSupplier' | translate }}" matTooltipPosition="above"
        class="token-custom-content">{{numberToken}}</span>
    </div>
  </div>

  <div class="block profile-block-top" data-test="cypress-profile-headbar">
    <div class="notification-custom custom-profile" [matMenuTriggerFor]="profile" (click)="rotateIcon(0)">
      <div class="card-custom-image" [ngStyle]="{'background-image': 'url(' + profileImage + ')'}"></div>
      <img class="chevron-down-custom" src="assets/images/topbr-icons/chevron-down-icon.svg" alt="chevron-down">
    </div>
    <mat-menu #profile="matMenu" class="profile-custom-menu">

      <div class="profile-menu-container" *ngIf="globalState.isOldPlan == true">

        <div class="profile-name-part">
          <div class="name">{{ currentUser.firstname}} {{ currentUser.lastname}}</div>
          <div class="email">{{ currentUser.email}}</div>
        </div>

        <div class="specs-section" *ngIf=" currentUser != undefined && currentUser != null && currentUser.role != 'Company Administrator'">
          <div (click)="goToOtherPage('my_profile')" class="menu-profile-item"
            *ngIf="viewButtonMyProfile && currentUser.logicRole && currentUser.logicRole !='studentAcademyLazy'">
            <img alt="my-profile" src="assets/images/icon/profile_outline.svg"> {{ 'MyProfile' | translate }}
          </div>
          <div (click)="goToOtherPage('my_profile')" class="menu-profile-item"
            *ngIf="viewButtonMyProfile && currentUser.logicRole && currentUser.logicRole =='studentAcademyLazy'">
            <img alt="my-profile"
              [src]="currentUser.logicRole =='studentAcademyLazy' ? 'assets/images/icon/key_blue.svg' :
              'assets/images/icon/profile_outline.svg'">
              Password
          </div>

          <div (click)="goToOtherPage('company_profile')"
            *ngIf=" currentUser != undefined && currentUser != null && isAllowed && currentUser.role == 'Client' && viewButtonCompanyProfile && !isEsprinet"
            class="menu-profile-item">
            <img alt="company-profile" src="assets/images/new-card-icons/company-blue.svg">
            {{ 'CompanyProfile' | translate }}
          </div>

          <div (click)="goToOtherPage('manage_users')"
           data-test="cypress-profile-manage-users-button"
            *ngIf=" currentUser != undefined && currentUser != null && isAllowed && currentUser.role == 'Client' && viewButtonManageUsers && !isEsprinet"
            class="menu-profile-item">
            <img alt="manage_users" src="assets/images/new-card-icons/peoples-blue.svg">
            {{ 'Manage Users Client' | translate }}
          </div>

          <div (click)="goToOtherPage('my_plans')"
            *ngIf=" currentUser != undefined && currentUser != null && isAllowed && currentUser.role == 'Client' && versionProduct == 2 && viewButtonMyPlans && !isEsprinet"
            class="menu-profile-item">
            <img alt="my_plans" src="assets/images/new-card-icons/dollar-blue.svg"> {{ 'My Plans' | translate }}
          </div>

          <div (click)="goToOtherPage('my_plans_supplier')"
            *ngIf=" currentUser != undefined && currentUser != null && isAllowed && currentUser.role == 'Supplier' && versionProduct == 2 && viewButtonMyPlans &&
            !isEsprinet"
            class="menu-profile-item">
            <img alt="my_plans" src="assets/images/new-card-icons/dollar-blue.svg"> {{ 'My Plans' | translate }}
          </div>

          <div (click)="goToOtherPage('manage_users_supplier')" *ngIf=" currentUser != undefined && currentUser != null && currentUser.role == 'Supplier' && !isEsprinet"
            class="menu-profile-item">
            <img alt="manage_users_supplier" src="assets/images/new-card-icons/peoples-blue.svg">
            {{ 'Manage Users Supplier' | translate }}
          </div>

          <!-- <div *ngIf="(currentUser.role == 'Client' || currentUser.role == 'Supplier') && versionProduct == 2"
            class="menu-profile-item" (click)="manageAccount();$event.stopPropagation()">
            <img alt="my plans" src="assets/images/new-card-icons/manage-blue.svg">
            {{ "ProfileMenu.Subscription" | translate }}
          </div> -->

          <div class="plans-part"
            *ngIf=" currentUser != undefined && currentUser != null && currentUser.role == 'Client' && state != 1 && versionProduct == 2 && !isEsprinet"
            [ngStyle]="{'background-color': (state == 4 || state == 3) ? 'var(--brandPrincipalColorLighter)' : 'var(--brandPrincipalColorLighter)' }">

            <div class="plan-name">
              <div class="name" [ngStyle]="{'color': state == 4 ? 'var(--grey)' : 'var(--brandPrincipalColor)' }">{{ namePlanCurrent}}</div>
              <div *ngIf="price != 0"class="cost" [ngStyle]="{'color': state == 4 ? 'var(--grey)' : 'var(--brandPrincipalColor)' }">{{ price}} € /
                {{ 'Year' | translate }} </div>
            </div>

            <div class="plan-bar">
              <div class="statistics">{{ creditsUser}} / {{ creditsTotal}} {{ 'CreditsUsed' | translate }}</div>
              <div class="bar-container">
                <div class="bar" [class.hight-score]="(fullBar >= 84 && fullBar <= 100)"
                  [class.medium-score]="(fullBar >= 60 && fullBar <= 83)"
                  [class.low-score]="(fullBar >= 0 && fullBar <= 59)" [ngStyle]="{ 'width' : fullBar + '%'}"></div>
              </div>
            </div>

            <div class="expired" [ngStyle]="{'color': state == 4 ? 'var(--brandSecondaryColorThreeLight)' : 'var(--grey)' }" *ngIf="limit != '31/12/9999'">
              {{ state == 4 ? ("ProfileMenu.Expired" | translate) : ('ExpiredOn' | translate) }} {{ limit }}
            </div>
            <div class="button-logout blue-default" (click)="goToOtherPage('my_plans')"
              *ngIf="(state == 4 || state == 3)">
              <img alt="logout" src="assets/images/new-card-icons/dollar-blue.svg">
              {{ "ProfileMenu.BuyNewPlan" | translate }}
            </div>

          </div>
          <div class="plans-part-no-plan"
            *ngIf=" currentUser != undefined && currentUser != null && currentUser.role == 'Client' && state == 1 && versionProduct == 2 && !isEsprinet">
            <div class="plan-name">
              <div class="name">{{ "ProfileMenu.NoSubscription" | translate }}</div>
              <div class="desc">{{ "ProfileMenu.NoSubscriptionDesc" | translate }}</div>
            </div>

            <div class="button-logout blue-default" (click)="goToOtherPage('my_plans')">
              <img alt="logout" src="assets/images/new-card-icons/dollar-blue.svg">
              {{ "ProfileMenu.BuyPlan" | translate }}
            </div>

          </div>

        </div>

        <div *ngIf="assumedUser == 0" class="button-logout blue-default" (click)="logout()">
          <img alt="logout" src="assets/images/new-card-icons/log-out-blue.svg"> {{ 'SignOut' | translate }}
        </div>

        <div *ngIf="assumedUser == 1" class="button-logout blue-default" (click)="returnAdmin()">
          <img alt="logout" src="assets/images/new-card-icons/log-out-blue.svg"> {{ 'ReturnBack' | translate }}
        </div>
      </div>

      <div class="profile-menu-container" *ngIf="globalState.isOldPlan == false">

        <div class="profile-name-part">
          <div class="name">{{ currentUser.firstname}} {{ currentUser.lastname}}</div>
          <div class="email">{{ currentUser.email}}</div>
        </div>

        <div class="specs-section">
          <div (click)="goToOtherPage('my_profile')" class="menu-profile-item"
            *ngIf="viewButtonMyProfile && currentUser.logicRole && currentUser.logicRole !='studentAcademyLazy'">
            <img alt="my-profile" src="assets/images/icon/profile_outline.svg"> {{ 'MyProfile' | translate }}
          </div>
          <div (click)="goToOtherPage('my_profile')" class="menu-profile-item"
            *ngIf="viewButtonMyProfile && currentUser.logicRole && currentUser.logicRole =='studentAcademyLazy'">
            <img alt="my-profile"
              [src]="currentUser.logicRole && currentUser.logicRole =='studentAcademyLazy' ? 'assets/images/icon/key_blue.svg' :
               'assets/images/icon/profile_outline.svg'">
            Password
          </div>

          <div (click)="goToOtherPage('manage_company_admin_users')"
            data-test="cypress-profile-manage-users-button"
            *ngIf=" currentUser != undefined && currentUser != null && currentUser.role == 'Company Administrator' && viewButtonManageUsers && isEsprinet"
            class="menu-profile-item">
            <img alt="manage_users" src="assets/images/new-card-icons/peoples-blue.svg">
            {{ 'Manage Users Client' | translate }}
          </div>

          <div (click)="goToOtherPage('company_profile')"
            *ngIf=" currentUser != undefined && currentUser != null && isAllowed && currentUser.role == 'Client' && viewButtonCompanyProfile && !isEsprinet"
            class="menu-profile-item">
            <img alt="company-profile" src="assets/images/new-card-icons/company-blue.svg">
            {{ 'CompanyProfile' | translate }}
          </div>

          <div (click)="goToOtherPage('manage_users')"
            *ngIf=" currentUser != undefined && currentUser != null && isAllowed && currentUser.role == 'Client' && viewButtonManageUsers && !isEsprinet"
            class="menu-profile-item">
            <img alt="manage_users" src="assets/images/new-card-icons/peoples-blue.svg">
            {{ 'Manage Users Client' | translate }}
          </div>

          <div (click)="goToOtherPage('my_plans')"
            *ngIf=" currentUser != undefined && currentUser != null && isAllowed && currentUser.role == 'Client' && versionProduct == 2 && viewButtonMyPlans && !isEsprinet"
            class="menu-profile-item">
            <img alt="my_plans" src="assets/images/new-card-icons/dollar-blue.svg"> {{ 'My Plans' | translate }}
          </div>

            <div (click)="goToOtherPage('my_plans_supplier')"
              *ngIf=" currentUser != undefined && currentUser != null && isAllowed && currentUser.role == 'Supplier' && versionProduct == 2 && viewButtonMyPlans &&
              !isEsprinet"
              class="menu-profile-item">
              <img alt="my_plans" src="assets/images/new-card-icons/dollar-blue.svg"> {{ 'My Plans' | translate }}
            </div>

          <div (click)="goToOtherPage('manage_users_supplier')"
            *ngIf=" currentUser != undefined && currentUser != null && currentUser.role == 'Supplier' && viewButtonManageUsersSupplier && isAllowed && !isEsprinet"
            class="menu-profile-item">
            <img alt="manage_users_supplier" src="assets/images/new-card-icons/peoples-blue.svg">
            {{ 'Manage Users Supplier' | translate }}
          </div>

    <!--  <div *ngIf="(currentUser.role == 'Client' || currentUser.role == 'Supplier') && versionProduct == 2"
            class="menu-profile-item" (click)="manageAccount();$event.stopPropagation()">
            <img alt="my plans" src="assets/images/new-card-icons/manage-blue.svg">
            {{ "ProfileMenu.Subscription" | translate }}
          </div> -->

          <div class="plans-part"
            *ngIf=" currentUser != undefined && currentUser != null && (currentUser.role == 'Client' || currentUser.role == 'Supplier') && state != 1 && versionProduct == 2 && !isEsprinet"
            [ngClass]="state == 4 ? 'plans-red' : 'plans-azure'">

            <div class="plan-name-new" *ngIf="!isEsprinet">
              <div class="title"> {{ namePlanCurrent}}</div>
              <div *ngIf="price != 0" class="subtitle">{{ price}} € / {{ 'Year' | translate }}</div>
            </div>

            <div class="expire-date" [ngStyle]="{'color': state == 4 ? 'var(--brandSecondaryColorThreeLight)' : 'var(--grey)' }"
              *ngIf="limit != '31/12/9999' && !isEsprinet">
              {{ state == 4 ? ("ProfileMenu.Expired" | translate) : ('ExpiredOn' | translate) }} {{ limit }}
            </div>

            <div class="add-on-block" *ngIf="modalAddOn && !isEsprinet">
              {{ currentUser.role == 'Client' ? ('Search Supplier' | translate) : ('Search Partner'| translate) }}<img src="assets/images/new-card-icons/ok.svg" alt="">
            </div>

            <div class="plan-button-container" *ngIf="state != 4 && !isEsprinet">
              <div class="plan-button blue-default" (click)="manageAccount();$event.stopPropagation()">
               {{("Manage" | translate)}}
              </div>
            </div>

            <div class="plan-button-container" *ngIf="state == 4 && !isEsprinet">
              <div class="plan-button medium-button blue-default" (click)="manageAccount();$event.stopPropagation()">
                {{("Manage" | translate)}}
              </div>
              <div class="plan-button medium-button dark-blue-default" (click)="goToOtherPage('my_plans')">
                {{("Renew" | translate)}}
              </div>
            </div>

            <!-- <div class="plan-name">
              <div class="name" [ngStyle]="{'color': state == 4 ? 'var(--grey)' : 'var(--brandPrincipalColor)' }">{{ namePlanCurrent}}</div>
              <div class="cost" [ngStyle]="{'color': state == 4 ? 'var(--grey)' : 'var(--brandPrincipalColor)' }">{{ price}} € /
                {{ 'Month' | translate }} </div>
            </div> -->

            <!-- <div class="plan-bar">
              <div class="statistics">{{ creditsUser}} / {{ creditsTotal}} {{ 'CreditsUsed' | translate }}</div>
              <div class="bar-container">
                <div class="bar" [class.hight-score]="(fullBar >= 84 && fullBar <= 100)"
                  [class.medium-score]="(fullBar >= 60 && fullBar <= 83)"
                  [class.low-score]="(fullBar >= 0 && fullBar <= 59)" [ngStyle]="{ 'width' : fullBar + '%'}"></div>
              </div>
            </div> -->

            <!-- <div class="expired" *ngIf="limit" [ngStyle]="{'color': state == 4 ? 'var(--brandSecondaryColorThreeLight)' : 'var(--grey)' }">
              {{ state == 4 ? ("ProfileMenu.Expired" | translate) : ('ExpiredOn' | translate) }} {{ limit }}
            </div>
            <div class="button-logout blue-default" (click)="goToOtherPage('my_plans')"
              *ngIf="(state == 4 || state == 3)">
              <img alt="logout" src="assets/images/new-card-icons/dollar-blue.svg">
              {{ "ProfileMenu.BuyNewPlan" | translate }}
            </div> -->

          </div>
          <div class="plans-part-no-plan plans-azure"
            *ngIf=" currentUser != undefined && currentUser != null && (currentUser.role == 'Client' || currentUser.role == 'Supplier') && state == 1 && versionProduct == 2
            && !isEsprinet">
              <div class="plan-name-new">
                <div class="title">  {{("PlanMenuTop.NoPlan" | translate)}}</div>
                <div class="subtitle-no-plan"> {{("PlanMenuTop.Text1" | translate)}} <br>  {{("PlanMenuTop.Text2" | translate)}}</div>
              </div>

              <div class="plan-button-container" *ngIf="state != 4">
                <div class="plan-button blue-default" (click)="goToOtherPage('my_plans')">
                  {{("PlanMenuTop.BuyPlan" | translate)}}
                </div>
              </div>
            <!-- <div class="plan-name">
              <div class="name">{{ "ProfileMenu.NoSubscription" | translate }}</div>
              <div class="desc">{{ "ProfileMenu.NoSubscriptionDesc" | translate }}</div>
            </div>

            <div class="button-logout blue-default" (click)="goToOtherPage('my_plans')">
              <img alt="logout" src="assets/images/new-card-icons/dollar-blue.svg">
              {{ "ProfileMenu.BuyPlan" | translate }}
            </div> -->

          </div>

        </div>

        <div *ngIf="assumedUser == 0" class="button-logout blue-default" (click)="logout()">
          <img alt="logout" src="assets/images/new-card-icons/log-out-blue.svg"> {{ 'SignOut' | translate }}
        </div>

        <div *ngIf="assumedUser == 1" class="button-logout blue-default" (click)="returnAdmin()">
          <img alt="logout" src="assets/images/new-card-icons/log-out-blue.svg"> {{ 'ReturnBack' | translate }}
        </div>
      </div>

    </mat-menu>
  </div>

  <div class="block upgrade-block-top"
    *ngIf=" currentUser != undefined && currentUser != null && (versionProduct == 2) &&
    ((globalState.idPlanCurrent != 13 && currentUser.role == 'Supplier')  && !isEsprinet)">
    <div class="upgrade-button"
      (click)="currentUser.role == 'Client' ? goToOtherPage('my_plans') : goToOtherPage('my_plans_supplier')">
      Upgrade
    </div>
  </div>

  <div class="block language-block-top">
    <div class="notification-custom custom-flag" [matMenuTriggerFor]="flags" (click)="rotateIcon(1)">
      <span>{{selectedLanguage.view}}</span>
      <img class="chevron-down-custom" src="assets/images/topbr-icons/chevron-down-icon.svg" alt=""
        alt="chevron-down">
    </div>
    <mat-menu #flags="matMenu" class="language-custom-menu">

      <button mat-menu-item *ngFor="let lang of languages" (click)="changeLanguage(lang)">
        <span>{{lang.icon}}</span>
      </button>

    </mat-menu>
  </div>

  <div class="block info-container" *ngIf=" currentUser != undefined && currentUser != null && currentUser.role != 'Administrator'">
    <div (click)="setTutorial()" class="cursor-pointer info-fix-custom "  *ngIf="tutorialMode == false && tutorialToShow.exist == true" matTooltip="Attiva Tutorial">
      <img src="assets/images/topbr-icons/info-fixed.svg" alt="" />
    </div>
    <div (click)="setTutorial()" class="cursor-pointer info-fix-custom info-active"  *ngIf="tutorialMode == true && tutorialToShow.exist == true" matTooltip="Disattiva Tutorial">
      <img src="assets/images/topbr-icons/info-fixed-white.svg" alt="" />
    </div>
    <div class="info-fix-custom info-disabled"  *ngIf="tutorialToShow.exist == false" matTooltip="Nessun tutorial disponibile">
      <img src="assets/images/topbr-icons/info-fixed-grey.svg" alt="" />
    </div>
  </div>

</div>
