import { CertificationsModule } from './certifications/certifications.module';
import { Routes } from '@angular/router';
import { DashboardSupplierModule } from './dashboard-supplier/dashboard-supplier.module';
import { DashboardClientModule } from './dashboard-client/dashboard-client.module';
import { FullComponent } from './layouts/full/full.component';
import { AppBlankComponent } from './layouts/blank/blank.component';
import { PositionFixedPriceModule } from './position-fixed-price/position-fixed-price.module';
import { ECreateCompanyMultiModule } from './e-create-company-multi/e-create-company-multi.module';
import { StudentDashboardModule } from './student-dashboard/student-dashboard.module';
import { OpenComponent } from './layouts/open/open.component';
export const AppRoutes: Routes = [

    {
        path: '',
        component: FullComponent,
        children: [
            {
                path: '',
                redirectTo: '/authentication/login',
                pathMatch: 'full'
            },
            {
                path: 'login',
                redirectTo: '/authentication/login',
                pathMatch: 'full'
            },
            {
              path: 'interview_organize',
              redirectTo: '/authentication/interview_organize',
              pathMatch: 'full'
            },
            {
              path: 'feedback_request',
              redirectTo: '/authentication/feedback_request',
              pathMatch: 'full'
            },
            {
              path: 'shared_profiles',
              redirectTo: '/authentication/shared_profiles',
              pathMatch: 'full'
            },
            {
              path: 'freelance',
              redirectTo: '/freelance',
              pathMatch: 'full'
            },

            {
              path: 'freelance_profile',
              loadChildren: () => import('./freelance-profile/freelance-profile.module').then(m => m.FreelanceProfileModule)
            },
            {
                path: 'dashboard_supplier',
                loadChildren: () => import('./dashboard-supplier/dashboard-supplier.module').then(m => m.DashboardSupplierModule)
            },
            {
              path: 'dashboard_freelance',
              loadChildren: () => import('./dashboard-freelance/dashboard-freelance.module').then(m => m.DashboardFreelanceModule)
            },
            {
              path: 'dashboard_academy',
              loadChildren: () => import('./dashboard-academy/dashboard-academy.module').then(m => m.DashboardAcademyModule)
            },
            {
                path: 'dashboard_client',
              loadChildren: () => import('./dashboard-client/dashboard-client.module').then(m => m.DashboardClientModule)
            },
            {
              path: 'search_supplier',
              loadChildren: () => import('./search-supplier/search-supplier.module').then(m => m.SearchSupplierModule)
            },
            {
              path: 'student_dashboard',
              loadChildren: () => import('./student-dashboard/student-dashboard.module').then(m => m.StudentDashboardModule)
            },
            {
              path: 'student_profile',
              loadChildren: () => import('./student-profile/student-profile.module').then(m => m.StudentProfileModule)
            },
            {
              path: 'student_collaborations',
              loadChildren: () => import('./student-collaborations/student-collaborations.module').then(m => m.StudentCollaborationsModule)
            },
            {
                path: 'plans',
                loadChildren: () => import('./plans/plans.module').then(m => m.PlansModule)
            },
            {
                path: 'plans_supplier',
                loadChildren: () => import('./plans_supplier/plans_supplier.module').then(m => m.PlansSupplierModule)
            },
            {
                path: 'configuration',
                loadChildren: () => import('./configuration/configuration.module').then(m => m.ConfigurationModule)
            },
            {
              path: 'complete_detail',
              loadChildren: () => import('./complete-details/complete-details.module').then(m => m.CompleteDetailsModule)
            },

            {
                path: 'my_profile',
                loadChildren: () => import('./my-profile/my-profile.module').then(m => m.MyProfileModule)
            },
            // {
            //     path: 'change_password',
            //     loadChildren: () => import('./change-password/change-password.module').then(m => m.ChangePasswordModule)
            // },
            {
                path: 'company_profile',
                loadChildren: () => import('./company-profile/company-profile.module').then(m => m.CompanyProfileModule)
            },
            {
                path: 'my_plans',
                loadChildren: () => import('./my-plans/my-plans.module').then(m => m.MyPlansModule)
            },
            {
                path: 'my_plans_supplier',
                loadChildren: () => import('./my-plans-supplier/my-plans-supplier.module').then(m => m.MyPlansSupplierModule)
            },
            {
              path: 'notifications',
              loadChildren: () => import('./notifications-page/notifications-page.module').then(m => m.NotificationsPageModule)
            },
            {
                path: 'technology',
                loadChildren: () => import('./technology/technology.module').then(m => m.TechnologyModule)
            },
            {
                path: 'job_sector',
                loadChildren: () => import('./job-sector/job-sector.module').then(m => m.JobSectorModule)
            },
            {
                path: 'company',
                loadChildren: () => import('./company/company.module').then(m => m.CompanyModule)
            },
            {
              path: 'employees',
              loadChildren: () => import('./employees/employees.module').then(m => m.EmployeesModule)
            },
            {
              path: 'need',
              loadChildren: () => import('./need/need.module').then(m => m.NeedModule)
            },

            {
              path: 'working_experience',
              loadChildren: () => import('./employee-work-experience/employee-work-experience.module').then(m => m.EmployeesWorkExperienceModule)
            },
            {
              path: 'digitize_cv',
              loadChildren: () => import('./digitize-cv/digitize-cv.module').then(m => m.DigitizeCvModule)
            },

            {
              path: 'supplier_profile',
              loadChildren: () => import('./supplier-profile/supplier-profile.module').then(m => m.SupplierProfileModule)
            },
            {
              path: 'collaborations_invite',
              loadChildren: () => import('./collaborations-invite/collaborations-invite.module').then(m => m.CollaborationsInviteModule)
            },
            {
              path: 'customer_profile',
                loadChildren: () => import('./customer-profile/customer-profile.module').then(m => m.CustomerProfileModule)
              },
              {
                path: 'collaborations_request',
                loadChildren: () => import('./collaborations-request/collaborations-request.module').then(m => m.CollaborationsRequestModule)
              },
              {
                path: 'ghost_account',
                loadChildren: () => import('./ghost-account/ghost-account.module').then(m => m.GhostAccountModule)
              },
              {
                path: 'collaborations_admin',
                loadChildren: () => import('./collaborations-admin/collaborations-admin.module').then(m => m.CollaborationsAdminModule)
              },
              {
                path: 'languages',
                loadChildren: () => import('./languages/languages.module').then(m => m.LanguagesModule)
              },
              {
                path: 'description_pages',
                loadChildren: () => import('./description-pages/description-pages.module').then(m => m.DescriptionPagesModule)
              },
              {
                path: 'email_notifications',
                loadChildren: () => import('./email-notification/email-notification.module').then(m => m.EmailNotificationModule)
              },
              {
                path: 'upload_cv',
                loadChildren: () => import('./employee-cv/employee-cv.module').then(m => m.EmployeeCVModule)
              },
              {
                path: 'employee_review',
                loadChildren: () => import('./employee-review/employee-review.module').then(m => m.EmployeeReviewModule)
              },
              {
                path: 'manage_admin_users',
                loadChildren: () => import('./manage-admin-users/manage-admin-users.module').then(m => m.ManageAdminUsersModule)
            },
            {
                path: 'manage_users',
                loadChildren: () => import('./manage-users/manage-users.module').then(m => m.ManageUsersModule)
              },
              {
                path: 'manage_users_supplier',
                loadChildren: () => import('./manage-users-supplier/manage-users-supplier.module').then(m => m.ManageUsersSupplierModule)
              },
              {
                path: 'position_list',
                loadChildren: () => import('./position-list/position-list.module').then(m => m.PositionListModule)
              },
              {
                path: 'position_list_fixed_price',
                loadChildren: () => import('./position-fixed-price/position-fixed-price.module').then(m => m.PositionFixedPriceModule)
              },
              {
                path: 'freelance_position_list',
                loadChildren: () => import('./freelance-position-list/freelance-position-list.module').then(m => m.FreelancePositionListModule)
              },
              {
                path: 'profile_available',
                loadChildren: () => import('./profile-available/profile-available.module').then(m => m.ProfileAvailableModule)
              },
              {
                path: 'saved_profiles',
                loadChildren: () => import('./saved-profiles/saved-profiles.module').then(m => m.SavedProfilesModule)
              },
              {
                path: 'pending_inscriptions',
                loadChildren: () => import('./pending-inscriptions/pending-inscriptions.module').then(m => m.PendingInscriptionsModule)
              },
            {
              path: 'certifications',
              loadChildren: () => import('./certifications/certifications.module').then(m => m.CertificationsModule)
            },
            {
              path: 'product_category',
              loadChildren: () => import('./product-category/product-category.module').then(m => m.ProductCategoryModule)
            },
            {
              path: 'company_profile_supplier',
              loadChildren: () => import('./company-profile-supplier/company-profile-supplier.module').then(m => m.CompanyProfileSupplierModule)
            },

            {

              path: 'accademy_profiles',
              loadChildren: () => import('./accademy-profiles/accademy-profiles.module').then(m => m.AccademyProfilesModule)
            },

            {
              path: 'chat_component',
              loadChildren: () => import('./chat-component/chat-component.module').then(m => m.ChatComponentModule)
            } ,

            {
              path: 'freelance_profile_det/:id_user_employee/:id_company/:id_token_usage_req/:function/:status',
              loadChildren: () => import('./freelance-profile-det/freelance-profile-det.module').then(m => m.FreelanceProfileDetModule)
            },
            {
              path: 'functions',
              loadChildren: () => import('./functions/functions.module').then(m => m.FunctionsModule)
            },
            {
              path: 'charts',
              loadChildren: () => import('./charts/charts.module').then(m => m.ChartsComponentModule)
            },
            {
              path: 'graphs',
              loadChildren: () => import('./charts/charts.module').then(m => m.ChartsComponentModule)
            },
            // ESPRINEt
            {
              path: 'find_services',
              loadChildren: () => import('./e-find-services/e-find-services.module').then(m => m.EFindServicesModule)
            },
            {
              path: 'services_list',
              loadChildren: () => import('./e-services-list/e-services-list.module').then(m => m.EServicesListModule)
            },
            {
              path: 'services_review',
              loadChildren: () => import('./e-services-review/e-services-review.module').then(m => m.EServicesReviewModule)
            },
            {
              path: 'company_list',
              loadChildren: () => import('./e-company-list/e-company-list.module').then(m => m.ECompanyListModule)
            },
            {
              path: 'manage_company_admin_users',
              loadChildren: () => import('./e-manage-admin-users/e-manage-admin-users.module').then(m => m.EManageAdminUsersModule)
            },
            {
              path: 'company_review',
              loadChildren: () => import('./e-company-review/e-company-review.module').then(m => m.ECompanyReviewModule)
            },
            {
              path: 'company_create',
              loadChildren: () => import('./e-create-company-multi/e-create-company-multi.module').then(m => m.ECreateCompanyMultiModule)
            },
            // ESPRINEt
            {
              path: 'company_subscriptions',
              loadChildren: () => import('./company-subscriptions/company-subscriptions.module').then(m => m.CompanySubscriptionsModule)
            },
            {
              path: 'coupons',
              loadChildren: () => import('./coupons/coupons.module').then(m => m.CouponsModule)
            },

        ]
    },
    {
        path: '',
        component: AppBlankComponent,
        children: [
            {
                path: 'authentication',
                loadChildren:
                    () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
            },
            {
              path: 'freelance',
              loadChildren:
                () => import('./freelance/freelance.module').then(m => m.FreelanceModule)
            },
             // ESPRINEt
            {
              path: 'registration_stepper',
              loadChildren:
                () => import('./e-registration-stepper/e-registration-stepper.module').then(m => m.ERegistrationStepperModule)
            }
             // ESPRINEt

        ]
    },
  {
    path: '',
    component: OpenComponent,
    children: [
      {
        path: 'open',
        loadChildren:
          () => import('./aopen/open.module').then(m => m.OpenModule)
      },

      {
        path: 'search_services',
        redirectTo: '/open/search_services',
        pathMatch: 'prefix'
      },
      {
        path: 'dashboard_client',
        redirectTo: '/open/dashboard_client',
        pathMatch: 'prefix'
      },
      {
        path: 'need_open_create',
        redirectTo: '/open/need_open_create',
        pathMatch: 'prefix'
      },
      {
        path: 'profile_available',
        redirectTo: '/open/profile_available',
        pathMatch: 'prefix'
      },
      {
        path: 'complete_detail',
        redirectTo: '/open/complete_detail',
        pathMatch: 'prefix'
      }
    ]
  },
    {
        path: '**',
        redirectTo: 'authentication/404'
    }
];
