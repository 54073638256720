<!-- ============================================================== -->
<!-- sidebar -->
<!-- ============================================================== -->
<mat-nav-list appAccordion>
  <div class="navbar-container-custom">
      <div class="logo-navbar">
        <div class="logo-part"  [style.background-image]="'url(' + logo + ')'">
          <!-- <img alt="homepage" src="{{logo}}"> -->
        </div>
      </div>

      <div class="navbar-elements-container" appAccordionLink *ngFor="let menuitem of user_menus" routerLinkActive="selected"
        group="{{menuitem.state}}" (click)="scrollToTop()">
        <div class="navbar-element">
          <!-- <img class="triangle-custom" src="assets/images/sidebar-icons/Union-full.svg" alt="sidebar" [ngClass]="{'show' : activeMenu == menuitem.name}"> -->

            <a class="menu-item-custom" appAccordionToggle
              data-test="cypress-sidebar-element"
              [ngStyle]="{'margin-bottom' : ((menuitem.state == 'position_list' && subMenuOpen) || ( menuitem.state == 'company_list' || menuitem.state == 'services_list')) ? ' 0px' : '20px'  }"
              [routerLink]="menuitem.state != 'position_list' && ( menuitem.state != 'company_list' && menuitem.state != 'services_list') ? ['/', menuitem.state] : []"
              (click)="setBg(menuitem.name,false)" (click)="menuitem.state == 'position_list' ? openSubMenu() :
              closeSubMenu()"
              [ngClass]="{'active' : activeMenu == menuitem.name}" *ngIf="menuitem.type === 'link'">
              <mat-icon class="custom-icon-sidebar" style="width: 24px;height: 24px;" svgIcon="{{menuitem.icon}}" ></mat-icon>
              <span  class="sidebar-elements" [ngClass]="navbarStretch ? 'sidebar-elements hide-it' : 'sidebar-elements show-it'" >{{ menuitem.name | translate }}</span>
              <!-- <span fxFlex></span> -->
              <span class="chip-supplier" *ngIf="menuitem.state === 'collaborations_request' && (counterListRequest != 0 && counterListRequest)">
                  <span class="label label-warning" style="border-radius: 20px;margin-left: 8px;" selected>{{counterListRequest}}</span>
              </span>
              <span class="chip-client" *ngIf="menuitem.state === 'collaborations_invite' && (counterListInvite != 0 && counterListInvite)">
                <span class="label label-warning" style="border-radius: 20px;margin-left: 8px;" selected>{{counterListInvite}}</span>
              </span>
              <span class="chip-client" *ngIf="menuitem.state === 'position_list'" style="margin-left: 12px;">
                <img src="assets/images/new-card-icons/chevron-down-white.svg" width="16px" height="16px" alt="">
              </span>
              <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
          </a>
        </div>
        <div class="sub-menu closed" *ngIf="menuitem.state === 'position_list'">
          <div class="time-material" [routerLink]="menuitem.state == 'position_list' ? ['/', menuitem.state] : []"> <div class="spot-circle"></div> Time & Material</div>
          <div class="fixed-price" [routerLink]="menuitem.state == 'position_list' ? ['/position_list_fixed_price'] : []"> <div class="spot-circle"></div> Fixed Price</div>
        </div>

         <div class="sub-menu" *ngIf="menuitem.state === 'company_list'">
           <div class="time-material" (click)="setBg(menuitem.name,false);isInStep(0,menuitem.name)"
            [ngClass]="{'active' : returned == 'company_list' && activeMenu == 'Company' }"
             [routerLink]="menuitem.state == 'company_list' ? ['/', menuitem.state] : []">
             <div class="spot-circle"></div> {{'CompleteList' | translate}}
           </div>
           <div class="fixed-price" (click)="setBg(menuitem.name,false);isInStep(1,menuitem.name)"
            [ngClass]="{'active' : returned == 'company_review' && activeMenu == 'Company'}"
             [routerLink]="menuitem.state == 'company_list' ? ['/company_review'] : []">
             <div class="spot-circle"></div> {{'ToReview' | translate}} <span
               [ngClass]="companyCounter != 0 ? 'label label-warning' : ''"
               style="border-radius: 20px;margin-left: 8px;">{{companyCounter != 0 ? companyCounter : ''}}</span>
           </div>
         </div>

          <div class="sub-menu" *ngIf="menuitem.state === 'services_list'">
            <div class="time-material" (click)="setBg(menuitem.name,false);isInStep(2,menuitem.name)"
              [ngClass]="{'active' : returned == 'services_list' && activeMenu == 'Company Services'}"
              [routerLink]="menuitem.state == 'services_list' ? ['/', menuitem.state] : []">
              <div class="spot-circle"></div>{{'CompleteList' | translate}}
            </div>
            <div class="fixed-price" (click)="setBg(menuitem.name,false);isInStep(3,menuitem.name) "
            [ngClass]="{'active' : returned == 'services_review' && activeMenu == 'Company Services'}"
              [routerLink]="menuitem.state == 'services_list' ? ['/services_review'] : []">
              <div class="spot-circle"></div> {{'ToReview' | translate}} <span
                [ngClass]="servicesCounter != 0 ? 'label label-warning' : ''"
                style="border-radius: 20px;margin-left: 8px;">{{servicesCounter != 0 ? servicesCounter : ''}}</span>
            </div>
          </div>
        </div>


    <div data-test="add-cta"
       [ngClass]="'add-cta'"
        *ngIf="((currentUser.role == 'Supplier' && ((currentDocStatus && currentDocStatus.id_doc_status == 2 || (currentDocStatus && currentDocStatus.id_doc_status == 4 && !expiredDate)) && planStatus == 1) && viewButtonAddProfile)
          || (currentUser.role == 'Client' && versionProduct == 2 && viewButtonAddProject)
          || (currentUser.role == 'Client' && versionProduct == 1 && currentUser.simulate_academy == 0 && viewButtonAddProject)
 		  || (currentUser.role == 'Accademy' && viewButtonAddProfile )
 		  || (currentUser.role == 'Client Employees' && versionProduct == 2 && viewButtonAddProject)
          || (currentUser.role == 'Client Employees' && versionProduct == 1 && currentUser.simulate_academy == 0 && viewButtonAddProject)
          || (currentUser.role == 'Supplier Management' && viewButtonAddProfile)
          || (currentUser.role == 'Management Accademy' && viewButtonAddProfile)) && isDomainEsprine == false"
         (click)="((currentUser.role == 'Supplier' && ((currentDocStatus && currentDocStatus.id_doc_status == 2 || (currentDocStatus && currentDocStatus.id_doc_status == 4 && !expiredDate)) && planStatus == 1) )
          || currentUser.role == 'Accademy'
          || currentUser.role == 'Supplier Management'
          || currentUser.role == 'Management Accademy' ) ? changeCreate('Profile') : changeCreate('Need') ">
      <div class="plus-container">
        <img src="assets/images/new-card-icons/plus-icon-white.svg" alt="plus">
      </div>
      <span>{{(currentUser.role == "Supplier" || currentUser.role == "Accademy" || currentUser.role == 'Supplier Management' || currentUser.role == 'Management Accademy') ? ('SidebarNew.AddProfile' | translate) : (currentUser.role == "Client" || currentUser.role == 'Client Employees' )? ('SidebarNew.AddProject' | translate) : ''}}</span>
    </div>

    <div data-test="add-cta" [ngClass]="'add-cta'" *ngIf="isEsprinet == true" (click)="createCompanyEsprinet('Add',{})">
       <!-- <div class="kiko">{{returned}} {{activeMenu}}</div> -->
       <div class="plus-container">
         <img src="assets/images/new-card-icons/plus-icon-white.svg" alt="plus">
       </div>
       <span>{{'AddCompany' | translate}}</span>
     </div>

  </div>

</mat-nav-list>
