import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Helper } from 'src/helper/helper';
@Injectable({
	providedIn: 'root'
})
export class AuthenticationService {

	constructor(private _http: HttpClient, private _helper: Helper) { }

	createuser(register: any): Promise<any> {
		return new Promise((resolve, reject) => {
			this._http.post(this._helper.getBaseUrl() + 'auth/register', { ...register })
				.subscribe((response: any) => {
					resolve(response);
				});
		});
	}
	loginuser(login: any): Promise<any> {
		return new Promise((resolve, reject) => {
			this._http.post(this._helper.getBaseUrl() + 'auth/login', { ...login })
				.subscribe((response: any) => {
					resolve(response);
				});
		});
	}
	getBrandColors(url: any): Promise<any> {

		return new Promise((resolve, reject) => {
			this._http.get(this._helper.getBaseUrl() + 'auth/getBrand?url=' + url)
				.subscribe((response: any) => {
					resolve(response);
				}, reject);
		});
	}

	checkForAuth(obj: any): Promise<any> {
		return new Promise((resolve, reject) => {
			this._http.post(this._helper.getBaseUrl() + 'auth/loginMobileAuthenticator', { ...obj })
				.subscribe((response: any) => {
					resolve(response);
				});
		});
	}

	getCodificValues(lang: any): Promise<any> {

		return new Promise((resolve, reject) => {
			this._http.post(this._helper.getBaseUrl() + 'encoded_value/listRegister', { language: lang })
				.subscribe((response: any) => {
					resolve(response);
				}, reject);
		});
	}

	loginAzure(token: any, idToken: any): Promise<any> {
		return new Promise((resolve, reject) => {
			this._http.post(this._helper.getBaseUrl() + 'auth/login', { token_azure: token, id_token: idToken })
				.subscribe((response: any) => {
					resolve(response);
				});
		});
	}

	loginGoogle(token: any, idToken: any, secret: any): Promise<any> {
		return new Promise((resolve, reject) => {
			this._http.post(this._helper.getBaseUrl() + 'auth/login', { token_google: token, id_token: idToken, secret: secret })
				.subscribe((response: any) => {
					resolve(response);
				});
		});
	}

	recoverySecret(token: any, idToken: any): Promise<any> {
		return new Promise((resolve, reject) => {
			this._http.post(this._helper.getBaseUrl() + 'auth/recoverySecret', { token_google: token, id_token: idToken })
				.subscribe((response: any) => {
					resolve(response);
				});
		});
	}


	logoutuser(login: any): Promise<any> {
		return new Promise((resolve, reject) => {
			this._http.post(this._helper.getBaseUrl() + 'auth/login', { ...login })
				.subscribe((response: any) => {
					resolve(response);
				});
		});
	}
	saveContacts(token: any, profile_id: any, specs: any): Promise<any> {
		return new Promise((resolve, reject) => {
			this._http.post(this._helper.getBaseUrl() + 'publicLinkProfile/saveContacts', { token: token, profile_id: profile_id, firstname: specs.name, lastname: specs.lastname, company_name: specs.company_name, email: specs.email, })
				.subscribe((response: any) => {
					resolve(response);
				});
		});
	}
	saveContactsCheck(token: any, profile_id: any, mail: any): Promise<any> {
		return new Promise((resolve, reject) => {
			this._http.post(this._helper.getBaseUrl() + 'publicLinkProfile/saveContacts', { token: token, profile_id: profile_id, firstname: null, lastname: null, company_name: null, email: mail, })
				.subscribe((response: any) => {
					resolve(response);
				});
		});
	}
	getTechnologiesFilters(token: any): Promise<any> {
		return new Promise((resolve, reject) => {
			this._http.get(this._helper.getBaseUrl() + 'publicLinkProfile/listTech?token=' + token)
				.subscribe((response: any) => {
					resolve(response);
				}, reject);
		});
	}
	getLanguagesFilters(token: any): Promise<any> {
		return new Promise((resolve, reject) => {
			this._http.get(this._helper.getBaseUrl() + 'publicLinkProfile/listLanguages?token=' + token)
				.subscribe((response: any) => {
					resolve(response);
				}, reject);
		});
	}

	filterProfile(obj: any, token: any): Promise<any> {
		return new Promise((resolve, reject) => {
			this._http.post(this._helper.getBaseUrl() + 'publicLinkProfile/filterProfile', { token: token, counter: obj.counter, cities: obj.cities, languages: obj.languages, technology: obj.technologies, min_rate: obj.min_rate, max_rate: obj.max_rate })
				.subscribe((response: any) => {
					resolve(response);
				}, reject);
		});
	}
	forgotPassword(data: any): Promise<any> {
		return new Promise((resolve, reject) => {
			this._http.post(this._helper.getBaseUrl() + 'auth/forgotPassword', { ...data })
				.subscribe((response: any) => {
					resolve(response);
				});
		});
	}
	resetPassword(data: any, reset_token: any): Promise<any> {
		return new Promise((resolve, reject) => {
			this._http.post(this._helper.getBaseUrl() + 'auth/resetPassword?reset_token=' + reset_token, { ...data })
				.subscribe((response: any) => {
					resolve(response);
				});
		});
	}

	disactivateNewsLetter(token: any): Promise<any> {
		return new Promise((resolve, reject) => {
			this._http.get(this._helper.getBaseUrl() + 'auth/disactiveNewsLetter?token=' + token)
				.subscribe((response: any) => {
					resolve(response);
				});
		});
	}

	checkInterviewToken(token: any): Promise<any> {
		return new Promise((resolve, reject) => {
			this._http.get(this._helper.getBaseUrl() + 'collaborations/interview/checkInterviewToken?token=' + token)
				.subscribe((response: any) => {
					resolve(response);
				});
		});
	}

	sendDates(token: any, user_id_update: any, state: any, interview_slot: any, users_invites: any): Promise<any> {
		return new Promise((resolve, reject) => {
			this._http.post(this._helper.getBaseUrl() + 'collaborations/interview/updateInterviewToken', { token: token, user_id_update: user_id_update, state: state, interview_slot: interview_slot, users_invites: users_invites })
				.subscribe((response: any) => {
					resolve(response);
				});
		});
	}

	validFreelance(id: any): Promise<any> {
		return new Promise((resolve, reject) => {
			this._http.post(this._helper.getBaseUrl() + 'freelance/checkValidFreelance', { id: id })
				.subscribe((response: any) => {
					resolve(response);
				});
		});
	}

	sendMailTwice(emailUser: any): Promise<any> {
		return new Promise((resolve, reject) => {
			this._http.post(this._helper.getBaseUrl() + 'auth/sendConfirmEmailApi', { email: emailUser })
				.subscribe((response: any) => {
					resolve(response);
				});
		});
	}

	disactiveNotification(token: any): Promise<any> {
		return new Promise((resolve, reject) => {
			this._http.get(this._helper.getBaseUrl() + 'auth/disactiveNotification?token=' + token)
				.subscribe((response: any) => {
					resolve(response);
				});
		});
	}

	checkSharedProfilesToken(profile_id: any, token: any): Promise<any> {
		return new Promise((resolve, reject) => {
			this._http.get(this._helper.getBaseUrl() + 'publicLinkProfile/chekPublicProfile?token=' + token + '&profile_id=' + profile_id)
				.subscribe((response: any) => {
					resolve(response);
				});
		});
	}

	confirmEmail(token: any): Promise<any> {
		return new Promise((resolve, reject) => {
			this._http.get(this._helper.getBaseUrl() + 'auth/confirmEmail?token=' + token)
				.subscribe((response: any) => {
					resolve(response);
				});
		});
	}

	checkEmail(email: any, language: any, role: any): Promise<any> {
		return new Promise((resolve, reject) => {
			this._http.post(this._helper.getBaseUrl() + 'auth/checkEmail', { email: email, language: language, role: role })
				.subscribe((response: any) => {
					resolve(response);
				});
		});
	}

	checkPiva(vat_number: any, role: any, foreign_company: any, language: any): Promise<any> {
		return new Promise((resolve, reject) => {
			this._http.post(this._helper.getBaseUrl() + 'auth/checkPiva', { vat_number: vat_number, role: role, foreign_company: foreign_company, language: language })
				.subscribe((response: any) => {
					resolve(response);
				});
		});
	}

	getCities(): Promise<any> {
		return new Promise((resolve, reject) => {
			this._http.get(this._helper.getBaseUrl() + 'city/list')
				.subscribe((response: any) => {
					resolve(response);
				});
		});
	}

	saveContact(obj: any): Promise<any> {
		return new Promise((resolve, reject) => {
			this._http.post(this._helper.getBaseUrl() + 'auth/saveContact', obj)
				.subscribe((response: any) => {
					resolve(response);
				});
		});
	}

	getLogoCompany(): Promise<any> {
		return new Promise((resolve, reject) => {
			this._http.get(this._helper.getBaseUrl() + 'auth/getLogo')
				.subscribe((response: any) => {
					resolve(response);
				});
		});
	}

	checkTokenRegistration(token: any): Promise<any> {
		return new Promise((resolve, reject) => {
			this._http.get(this._helper.getBaseUrl() + 'auth/checkTokenRegistration?token=' + token)
				.subscribe((response: any) => {
					resolve(response);
				});
		});
	}
	checkTokenExternalFeedback(token: any): Promise<any> {
		return new Promise((resolve, reject) => {
			this._http.get(this._helper.getBaseUrl() + 'feedback/checkTokenExternalFeedback?token=' + token)
				.subscribe((response: any) => {
					resolve(response);
				});
		});
	}
	checkTokenExternalFeedbackCollaboration(token: any): Promise<any> {
		return new Promise((resolve, reject) => {
			this._http.get(this._helper.getBaseUrl() + 'feedback/checkTokenExternalFeedbackCollaboration?token=' + token)
				.subscribe((response: any) => {
					resolve(response);
				});
		});
	}

	addFeedBackExternal(data: any): Promise<any> {
		return new Promise((resolve, reject) => {
			this._http.post(this._helper.getBaseUrl() + 'feedback/addFeedbackExternal', data)
				.subscribe((response: any) => {
					resolve(response);
				});
		});
	}

	addFeedbackExternalCollaboration(data: any): Promise<any> {
		return new Promise((resolve, reject) => {
			this._http.post(this._helper.getBaseUrl() + 'feedback/addFeedbackExternalCollaboration', data)
				.subscribe((response: any) => {
					resolve(response);
				});
		});
	}

	getParamLogin(token: any): Promise<any> {
		return new Promise((resolve, reject) => {
			this._http.get(this._helper.getBaseUrl() + 'auth/getParamLogin?token=' + token)
				.subscribe((response: any) => {
					resolve(response);
				});
		});
	}


	storeParamLoginAuth(domain_client: any, url: any, assumedUser: any, versionProduct: any, loginUser: any, loginUserToken: any, descriptionPages: any, email: any, tutorialPage: any, functions: any): Promise<any> {
		return new Promise((resolve, reject) => {
			this._http.post(this._helper.getBaseUrl() + 'auth/storeParamLoginAuth',
				{
					domain_client: domain_client,
					url_redirect: url,
					assumedUser: assumedUser,
					versionProduct: versionProduct,
					loginUser: loginUser,
					loginUserToken: loginUserToken,
					descriptionPages: descriptionPages,
					email: email,
					tutorialPage: tutorialPage,
					functions: functions
				})
				.subscribe((response: any) => {
					resolve(response);
				}, reject);
		});
	}

	getVersionProduct(): Promise<any> {
		return new Promise((resolve, reject) => {
			this._http.get(this._helper.getBaseUrl() + 'auth/getVersionProduct')
				.subscribe((response: any) => {
					resolve(response);
				});
		});
	}

	getConfigurationLogin(): Promise<any> {
		return new Promise((resolve, reject) => {
			this._http.get(this._helper.getBaseUrl() + 'auth/getConfigurationLogin')
				.subscribe((response: any) => {
					resolve(response);
				});
		});
	}

	switchVersionProduct(type_version: any): Promise<any> {
		return new Promise((resolve, reject) => {
			this._http.post(this._helper.getBaseUrl() + 'auth/switchVersionProduct', { type_version: type_version })
				.subscribe((response: any) => {
					resolve(response);
				});
		});
	}


	getOnboardingCustomer(token: any): Promise<any> {
		return new Promise((resolve, reject) => {
			this._http.get(this._helper.getBaseUrl() + 'auth/onboardingCustomer?token=' + token)
				.subscribe((response: any) => {
					resolve(response);
				});
		});
	}


}
