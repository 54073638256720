<div class="custom-modal">
  <div class="menu-container">
    <div class="title" *ngIf="local_data.action != 9 && local_data.action != 10">
      {{local_data.action == 1 ?  (local_data.state_function == 'chat' ? (('BlockModalCopy.TextForChat' | translate) + ' ' + local_data.blocked_for_plan_detail.name_function + ' ' + ('BlockModalCopy.Title' | translate))
       : local_data.state_function == 'download_cv' ? (('BlockModalCopy.TextForDownload' | translate) +  ' ' +  local_data.blocked_for_plan_detail.name_function + ' ' + ('BlockModalCopy.Title' | translate))
       : local_data.state_function == 'information_blocked' ? (('BlockModalCopy.TextForDetailCompany' | translate) +  ' ' +  local_data.blocked_for_plan_detail.name_function + ' ' + ('BlockModalCopy.Title' | translate)) :
       (local_data.blocked_for_plan_detail.name_function + ' ' + ('BlockModalCopy.Title' | translate))) :
      ('BlockModalCopy.TextToLimitsTitle' | translate) + ' ' + local_data.blocked_for_limit_detail.name_limit }}
    </div>
    <div class="title" *ngIf="local_data.action == 9">
     {{ ('BlockModalCopy.TextForNoAddon' | translate) }}
    </div>
    <div class="title" *ngIf="local_data.action == 10">
      {{ local_data.blocked_for_limit_detail.name_limit == 'no addon' ?
        ('BlockModalCopy.TextForNoAddon' | translate) :  (('BlockModalCopy.TextForNoAddonLimit' | translate) + ' ' + local_data.blocked_for_limit_detail.name_limit ) }}
    </div>

    <form #userForm="ngForm">
      <div class="content"
        *ngIf="local_data.action == 1">
        <div class="text">
          {{local_data.blocked_for_plan_detail.name_function}} {{('BlockModalCopy.Text1' | translate)}}
          {{local_data.blocked_for_plan_detail.name_plan_recommended}}. {{('BlockModalCopy.Text' | translate)}} <a
            href="mailto:sales@timeflow.it">sales&#64;timeflow.it.</a>
        </div>

        <div class="buttons">
          <button class="button-medium blue-default" (click)="closeDialog()">
            {{ 'Cancel' | translate }}
          </button>
          <button class="button-medium dark-blue-default " (click)="doAction()">
           Upgrade
            <mat-icon *ngIf="loading">
              <mat-spinner diameter="20"> </mat-spinner>
            </mat-icon>
          </button>
        </div>
        <div class="register-sec" *ngIf="local_data.create == true">
          <span (click)="goToCreateNeed()"
            class="link-custom">{{'BlockModalCopy.noBuy' | translate}}</span>
        </div>
      </div>

       <div class="content" *ngIf="local_data.action != 1 && local_data.action != 9 && local_data.action != 10">
         <div class="text">
          {{('BlockModalCopy.TextToLimits2'| translate)}} {{local_data.blocked_for_limit_detail.name_limit}}
          {{('BlockModalCopy.TextToLimits3'| translate)}} <a href="mailto:sales@timeflow.it">sales&#64;timeflow.it.</a>
         </div>

         <div class="buttons">
           <button class="button-medium blue-default" (click)="closeDialog()">
             {{ 'Cancel' | translate }}
            </button>
            <button class="button-medium dark-blue-default" (click)="doAction()">
              Upgrade
              <mat-icon *ngIf="loading">
                <mat-spinner diameter="20"> </mat-spinner>
              </mat-icon>
            </button>
         </div>
       </div>

         <div class="content" *ngIf="local_data.action == 9">
           <div class="text"> {{ (currentUser.role == 'Client' || currentUser.role == 'Client Employees') ? ('BlockModalCopy.TextForNoAddonClient'| translate)
              : ('BlockModalCopy.TextForNoAddonSupplier'| translate) }}
            <a href="mailto:sales@timeflow.it">sales&#64;timeflow.it.</a>
           </div>

           <div class="buttons">
             <button class="button-medium blue-default" (click)="closeDialog()">
               {{ 'Cancel' | translate }}
             </button>
             <button class="button-medium dark-blue-default" (click)="doAction()">
               Upgrade
               <mat-icon *ngIf="loading">
                 <mat-spinner diameter="20"> </mat-spinner>
               </mat-icon>
             </button>
           </div>
         </div>

           <div class="content" *ngIf="local_data.action == 10">
             <div class="text">
              {{ ('BlockModalCopy.TextForAddonError'| translate) }}
               <a href="mailto:sales@timeflow.it">sales&#64;timeflow.it.</a>
             </div>

             <div class="buttons">
               <button class="button-big blue-default" (click)="closeDialog()">
               Chiudi
               </button>

             </div>
           </div>


    </form>
  </div>
